<template>
   <div class="b-participant-table-row" :class="{
      'is-completed'                : isCompleted,
      'is-being-filled'             : isBeingFilled ,
      'is-row-uncollapsed'          : isRowUncollapsed ,
   }">
      <!-- main row -->
      <div class="b-participant-table-row__top-wrap">
         <!-- count -->
         <div class="b-participant-table-row__count">
            <div class="b-participant-table-row__student-count">
               <template v-if="isCompleted">
                  {{ participantNumber }}
               </template>
            </div>
         </div>

         <!-- name -->
         <InputText
            type="text"
            v-model="participant.first_name"
            :placeholder="$t('first_name') + ' *'"
            class="b-participant-table-row__name"
            :disabled="disableMainInputs"
            @focus="selectedInputName = 'first_name'"
         />

         <!-- last name -->
         <transition name="fade">
            <InputText
               type="text"
               v-model="participant.last_name"
               class="b-participant-table-row__last-name"
               :disabled="disableMainInputs"
               @focus="selectedInputName = 'last_name'"
               :placeholder="$t('last_name') + ' *'"
               v-show="isBeingFilled || isCompleted || isRowUncollapsed"
            />
         </transition>

         <!-- gender -->
         <transition name="fade">
            <Dropdown
               v-model="participant.gender"
               :disabled="disableMainInputs"
               @click="selectedInputName = 'gender'"
               class="b-participant-table-row__gender"
               optionLabel="label"
               optionValue="value"
               :options="participantGenderOptions"
               :showClear="true"
               :placeholder="$t('gender') + ' *'"
               v-show="isBeingFilled || isCompleted || isRowUncollapsed"
            />
         </transition>

         <!-- birthdate -->
         <transition name="fade">
            <Calendar
               class="b-participant-table-row__birth-date"
               :showIcon="true"
               v-model="birthDate"
               dateFormat="dd.mm.yy"
               :maxDate="new Date()"
               :showButtonBar="true"
               :disabled="disableMainInputs"
               :placeholder="$t('select_date_format') + ' *'"
               @date-select="setParticipantDate('birth_date')"
               @clear-click="onClearDate('birth_date')"
               @input="onDateInput($event, 'birth_date')"
               v-show="isBeingFilled || isCompleted || isRowUncollapsed"
            />
         </transition>

         <!-- infos wrap -->
         <div class="b-participant-table-row__infos-wrap" v-if="isBeingFilled || isCompleted">
            <!-- documents -->
            <transition name="fade">
               <div
                  class="b-participant-table-row__info-item is-document-item" 
                  :class="{ 'is-completed' : hasDocumentInfoCompleted }"
                  @click="showUncollapsedRow"
                  v-tooltip.top="$t('documents_tooltip_text')"
                  v-if="isPassportRequired"
               >
                  <template v-if="hasDocumentInfoCompleted">
                     {{ $t('documents_completed') }}
                  </template>

                  <template v-else>
                     {{ $t('documents_missing') }}
                  </template>
               </div>
            </transition>

            <!-- allergies -->
            <transition name="fade">
               <div 
                  class="b-participant-table-row__info-item is-allergy-item"
                  v-if="hasAlergiesInfo"
                  @click="showUncollapsedRow"
                  v-tooltip.top="$t('allergies_tooltip_text')"
               >
                  {{ $t('has_allergies') }}
               </div>
            </transition>

            <!-- comments -->
            <transition name="fade">
               <div
                  class="b-participant-table-row__info-item is-comment-item" 
                  v-if="hasCommentsInfo"
                  @click="showUncollapsedRow"
                  v-tooltip.top="$t('comments_tooltip_text')"
               >
                  {{ $t('has_comments') }}
               </div>
            </transition>
         </div>

         <!-- clear icon -->
         <span
            class="b-participant-table-row__clear-btn"
            @click="clearRow"
            v-if="canEditParticipantList && (isBeingFilled || isCompleted)"
         >
            {{ $t('clear') }}
         </span>

         <!-- uncollapse arrow -->
         <span
            class="b-participant-table-row__uncollapse-btn"
            @click="toggleUncollapsedRow" 
         >
         </span>
      </div>

      <!-- collapsed row -->
      <transition
         name="toggle-row"
         mode="out-in"
         v-on:before-enter="toggleRowBeforeEnter"
         v-on:enter="toggleRowEnter"
         v-on:before-leave="toggleRowBeforeLeave"
         v-on:leave="toggleRowLeave"
      >
         <div 
            class="b-participant-table-row__collapsed-wrap"
            v-show="isRowUncollapsed"
         >
            <div class="b-participant-table-row__collapsed-inner">
               <!-- other fields block -->
               <div class="h-flex-row-left-top">
                  <!-- nationality -->
                  <div class="b-participant-table-row__nationality field">
                     <label class="b-participant-table-row__input-label" v-html="$t('nationality')"></label>

                     <Dropdown 
                        v-model="participant.nationality"
                        :disabled="disableInputs"
                        @click="selectedInputName = 'nationality'"
                        optionLabel="label" 
                        optionValue="value"
                        :options="countryOptions"
                        :placeholder="$t('nationality')"
                     />
                  </div>

                  <!-- allergies -->
                  <div class="b-participant-table-row__allergies field">
                     <label class="b-participant-table-row__input-label" v-html="$t('allergies')"></label>

                     <Textarea
                        v-model="participant.allergies"
                        :disabled="disableInputs"
                        :autoResize="true"
                        class="form-control"
                        @input="selectedInputName = 'allergies'"
                     />
                  </div>

                  <!-- comments -->
                  <div class="b-participant-table-row__comments field">
                     <label class="b-participant-table-row__input-label">
                        {{ $t('comments') }}
                     </label>

                     <Textarea
                        v-model="participant.others"
                        :disabled="disableInputs"
                        :autoResize="true"
                        @click="selectedInputName = 'others'"
                     />
                  </div>
               </div>

               <!-- document fields block-->
               <template v-if="isPassportRequired">
                  <div class="b-participant-table-row__collapsed-row-title">
                     {{ $t("participant_documents_title") }}
                  </div>

                  <div class="h-flex-row-left-top">
                     <!-- type -->
                     <div class="b-participant-table-row__document-type field">
                        <label class="b-participant-table-row__input-label is-required">
                           {{ $t('document_type') }}
                        </label>

                        <Dropdown
                           v-model="participant.document_type"
                           :disabled="disableInputs"
                           @click="selectedInputName = 'document_type'"
                           optionLabel="label" 
                           optionValue="value"
                           :options="participantDocumentTypeOptions"
                           :placeholder="$t('select')"
                           :showClear="false"
                        />
                     </div>

                     <!-- document number -->
                     <div class="b-participant-table-row__document-number field">
                        <label class="b-participant-table-row__input-label is-required">
                           {{ $t('document_number') }}
                        </label>

                        <InputText 
                           type="text"
                           v-model="participant.document_nr"
                           :placeholder="$t('select')"
                           @focus="selectedInputName = 'document_nr'"
                           :disabled="disableInputs"
                        />
                     </div>

                     <!-- issuing_country -->
                     <div class="b-participant-table-row__document-country field">
                        <label class="b-participant-table-row__input-label is-required">
                           {{ $t('document_country') }}
                        </label>

                        <Dropdown
                           v-model="participant.document_issuing_country_id"
                           :disabled="disableInputs"
                           @click="selectedInputName = 'document_issuing_country_id'"
                           class="form-control"
                           optionLabel="label"
                           optionValue="value"
                           :options="countryOptions"
                           :showClear="true"
                           :placeholder="$t('select')"
                        />
                     </div>

                     <!-- expiration -->
                     <div class="b-participant-table-row__document-expiration field">
                        <label class="b-participant-table-row__input-label is-required">
                           {{ $t('document_expiration') }}
                        </label>

                        <Calendar
                           :showIcon="true"
                           :minDate="expirationDateMinDate"
                           v-model="documentExpirationDate"
                           dateFormat="dd.mm.yy"
                           :disabled="disableInputs"
                           :placeholder="$t('select_date_format')"
                           @clear-click="onClearDate('document_expiration_date')"
                           @input="onDateInput($event, 'document_expiration_date')"
                           @date-select="setParticipantDate('document_expiration_date')"
                        />
                     </div>

                     <!-- issued on -->
                     <div class="b-participant-table-row__document-issued field">
                        <label class="b-participant-table-row__input-label is-required">
                           {{ $t('issued_on') }}
                        </label>

                        <Calendar
                           :showIcon="true"
                           v-model="documentIssuedOn"
                           :maxDate="issueDateMaxDate"
                           dateFormat="dd.mm.yy"
                           :disabled="disableInputs"
                           :placeholder="$t('select_date_format')"
                           @clear-click="onClearDate('document_issue_date')"
                           @input="onDateInput($event, 'document_issue_date')"
                           @date-select="setParticipantDate('document_issue_date')"
                        />
                     </div>
                  </div>
               </template>
            </div>
         </div>
      </transition>
   </div>
</template>

<script>
   import _ from 'lodash';
   import dayjs from 'dayjs';
   import dateHelper from '@/helpers/date.js';
   import { formats } from '@/constants/date';
   import Calendar from 'primevue/calendar';
   import Dropdown from 'primevue/dropdown';
   import InputText from 'primevue/inputtext';
   import Textarea from 'primevue/textarea';
   import Tooltip from 'primevue/tooltip';
   import { mapState, mapMutations } from 'vuex';

   export default {
      components: {
         Calendar,
         Dropdown,
         InputText,
         Textarea,
      },
      props: {
         participantProp: {
            type: Object,
            required: true,
         },
         participantNumber: {
            type: Number,
            default: 0,
         },
      },
      data() {
         return {
            birthDate: null,
            documentExpirationDate: null,
            documentIssuedOn: null,
            expirationDateMinDate: null,
            hasAllergies: false,
            issueDateMaxDate: null,
            isRecoveredForTrip: false,
            isRowUncollapsed: false,
            isVaccinatedForTrip: false,
            originalParticipantData: {},
            participant: null,
            participantDocumentTypeOptions: [
               {value: 1, label: this.$t("passport") },
               {value: 2, label: this.$t("personnel_pass") },
               {value: 3, label: this.$t("other") },
            ],
            participantGenderOptions: [
               {value: 'm', label: this.$t("male") },
               {value: 'f', label: this.$t("female") },
               {value: 'o', label: this.$t("gender_other") },
            ],
            selectedInputName: '',
         };
      },
      directives: {
         'tooltip': Tooltip
      },
      computed: {
         ...mapState({
            canEditParticipantList: state => state.canEditParticipantList,
            countries: state => state.countries,
            isDB: state => state.isDb,
            isPartner: state => state.isPartner,
            isPassportRequired: state => state.isPassportRequired,
            participantListIsLocked: state => state.participantListIsLocked,
         }
         ),
         isCompleted() {
            if (!this.participant) {
               return false;
            }

            let basicFieldsAreComplete = !!this.participant.gender &&
               !!this.participant.last_name &&
               !!this.participant.first_name &&
               !!this.participant.birth_date;

            if (this.isPassportRequired) {
               return basicFieldsAreComplete && this.hasDocumentInfoCompleted;
            }

            return basicFieldsAreComplete;
         },
         isBeingFilled() {
            if (this.participant.isCompleted) {
               return false;
            }

            return !!this.participant.others ||
               !!this.participant.gender ||
               !!this.participant.last_name ||
               !!this.participant.allergies ||
               !!this.participant.birth_date ||
               !!this.participant.first_name ||
               !!this.participant.document_nr;
         },
         hasFirstNameFilled() {
            if (!this.participant) {
               return false;
            }

            return !!this.participant.first_name && !this.participant.isCompleted;
         },
         hasCommentsInfo() {
            return this.participant.others != '';
         },
         hasAlergiesInfo() {
            return this.participant.allergies.replace(/\s/g, '').length > 0;
         },
         hasDocumentInfoCompleted() {
            return !!this.participant.document_nr &&
               !!this.participant.document_type &&
               !!this.participant.document_issue_date &&
               !!this.participant.document_expiration_date &&
               !!this.participant.document_issuing_country_id;
         },
         disableMainInputs() {
            if (this.isDB) {
               return false;
            }

            if (this.isPartner) {
               return true;
            }

            return !this.canEditParticipantList;
         },
         disableInputs() {
            if (this.isDB) {
               return false;
            }

            if (this.isPartner) {
               return true;
            }

            return !this.isBeingFilled && this.participantListIsLocked;
         },
         countryOptions() {
            return this.countries.map((country) => {return { label: country.translatedName, value: country.id }})
         }
      },
      methods: {
         onDateInput(event, fieldName) {
            let value = null;
            switch(fieldName) {
               case 'birth_date':
                  value = this.birthDate;
                  break;
               case 'document_expiration_date':
                  value = this.documentExpirationDate;
                  break;
               case 'document_issue_date':
                  value = this.documentIssuedOn;
                  break;
            }
            if (!value) {
               this.selectedInputName = fieldName;
               this.participant[fieldName] = null;
               return;
            }

            this.setParticipantDate(fieldName);
         },
         onClearDate(fieldName) {
            // this[fieldName] = null;
            this.selectedInputName = fieldName;
            this.participant[fieldName] = null;
         },
         setParticipantDate(fieldName) {
            let selectedDate = null;
            this.selectedInputName = fieldName;

            switch(fieldName) {
               case 'birth_date':
                  selectedDate = dayjs(this.birthDate).format(formats.DATABASE);
                  break;
               case 'document_expiration_date':
                  selectedDate = dayjs(this.documentExpirationDate).format(formats.DATABASE);
                  break;
               case 'document_issue_date':
                  selectedDate = dayjs(this.documentIssuedOn).format(formats.DATABASE);
                  break;
            }
               
            this.participant[fieldName] = selectedDate;
         },
         ...mapMutations([
            'setUnsavedChanges',
         ]),
         toggleUncollapsedRow() {
            this.isRowUncollapsed = !this.isRowUncollapsed;
         },
         showUncollapsedRow() {
            this.isRowUncollapsed = true;
         },
         clearRow() {
            this.participant.room = '';
            this.participant.gender = null;
            this.participant.others = '';
            this.participant.last_name = '';
            this.participant.allergies = '';
            this.participant.first_name = '';
            this.participant.birth_date = '';
            this.participant.document_nr = null;
            this.participant.nationality = '';
            this.participant.document_type = null;
            this.participant.document_issue_date = '';
            this.participant.document_expiration_date = '';
            this.participant.document_issuing_country_id = '';
            this.toggleUnsavedChanges();

            setTimeout(()=> {
               this.isRowUncollapsed = false;
            }, 200);
         },
         toggleUnsavedChanges() {
            this.setUnsavedChanges(true);
         },
         participantEdited() {
            if (!this.selectedInputName) {
               return;
            }

            if (!this.isRowUncollapsed) {
               this.isRowUncollapsed = true;
            }

            this.addUpdationInfoToParticipant();
            this.toggleUnsavedChanges();
         },
         addUpdationInfoToParticipant() {
            if (!this.originalParticipantData.id) {
               return;
            }

            let editedFieldName = this.selectedInputName;
            let updationInfo = {
               newValue: this.participant[editedFieldName],
               oldValue: this.originalParticipantData[editedFieldName]
                  ? this.originalParticipantData[editedFieldName]
                  : this.$t('no_value')
            };

            this.participant.updationInfo[editedFieldName] = updationInfo;
         },
         initCalendars() {
            this.expirationDateMinDate = new Date();
            this.issueDateMaxDate  = new Date();

            this.birthDate = this.participant.birth_date
               ? new Date(dateHelper.toISOstring(this.participant.birth_date))
               : null;

            this.documentExpirationDate = this.participant.document_expiration_date
               ? new Date(dateHelper.toISOstring(this.participant.document_expiration_date))
               : null;

            this.documentIssuedOn = this.participant.document_issue_date
               ? new Date(dateHelper.toISOstring(this.participant.document_issue_date))
               : null;
         },
         toggleRowBeforeEnter: function(el) {
            el.style.height = '0';
            el.style.opacity = '0';
         },
         toggleRowEnter: function(el) {
            el.style.height = el.scrollHeight + 'px';
            el.style.opacity = '1';
         },
         toggleRowBeforeLeave: function(el) {
            el.style.height = el.scrollHeight + 'px';
            el.style.opacity = '0';
         },
         toggleRowLeave: function(el) {
            el.style.height = '0';
         },
      },
      watch: {
         'participant.room': { handler: 'participantEdited'},
         'participant.others': { handler: 'participantEdited'},
         'participant.gender': { handler: 'participantEdited'},
         'participant.allergies': { handler: 'participantEdited'},
         'participant.is_healthy': { handler: 'participantEdited'},
         'participant.last_name': { handler:  'participantEdited'},
         'participant.birth_date': { handler: 'participantEdited'},
         'participant.document_nr': { handler: 'participantEdited'},
         'participant.first_name': { handler:  'participantEdited'},
         'participant.nationality': { handler: 'participantEdited'},
         'participant.is_vaccinated': { handler: 'participantEdited'},
         'participant.document_type': { handler: 'participantEdited'},
         'participant.document_issue_date': { handler: 'participantEdited'},
         'participant.document_expiration_date': { handler: 'participantEdited'},
         'participant.document_issuing_country_id': { handler: 'participantEdited'},
      },
      created() {
         this.participant = this.participantProp;
         this.initCalendars();
         this.participant.updationInfo = {};
         this.originalParticipantData = _.cloneDeep(this.participant);
         this.participant['originalParticipantData'] = this.originalParticipantData;

         if (this.participant.allergies.replace(/\s/g, '').length > 0) {
            this.hasAllergies = true;
         }
      },
   };
</script>

<style lang="less">
   .b-participant-table-row {
      border-bottom: solid 1px rgba(var(--color-gray-rgb), .15);
      margin-bottom: 8px;
      min-width: 850px;
      .h-shadow;

      &__top-wrap {
         padding: 7px 10px;
         position: relative;
         .h-flex-row-left-center;
      }

      &__collapsed-wrap {
         // visibility managed via vue transitions
      }

      &__collapsed-inner {
         background-color: var(--color-gray-light);
         margin: 5px 10px 25px 45px;
         padding: 20px;
      }

      &__student-count { // style for count & dot
         color: var(--color-ok);
         width: 25px;
         .h-font-bold;
         .flex-shrink(0);

         &:before { // red dot
            content: "";
            width: 10px;
            height: $width;
            display: inline-block;
            background-color: hsl(var(--color-red-hs) 60%);
            border-radius: 100%;
         }
      }

      .p-inputtext { // make input shorter
         padding-top: 0.7em;
         padding-bottom: $padding-top;
      }

      .p-calendar .p-button.p-button-icon-only { // make calendar input shorter
          height: 36px;
          padding-top: 0;
          padding-bottom: 0;
      }

      &__name {
         width: var(--name-width) !important;
         margin-left: calc(~"var(--gap) - 15px");
         .flex-shrink(0);
      }

      &__last-name {
         width: var(--last-name-width) !important;
         margin-left: var(--gap);
         .flex-shrink(0);
      }

      &__gender {
         width: var(--gender-width) !important;
         margin-left: var(--gap);
         .flex-shrink(0);
      }

      &__birth-date {
         margin-left: var(--gap);
         .flex-shrink(0);
      }

      &__nationality {
         width: var(--nationality-width);
      }

      &__allergies {
         width: var(--allergies-width);
         margin-left: var(--gap);
      }

      &__comments {
         width: var(--comments-width);
         margin-left: var(--gap);
      }

      &__document-type {
         width: var(--document-type-width);
      }

      &__document-number {
         width: var(--document-number-width);
         margin-left: var(--gap);
      }

      &__document-country {
         width: var(--document-country-width);
         margin-left: var(--gap);
      }

      &__document-expiration {
         margin-left: var(--gap);
      }

      &__document-issued {
         margin-left: var(--gap);
      }

      &__infos-wrap {
         .flex-grow(1);
         .h-flex-row-left-center;
      }

      &__info-item {
         margin-left: 20px;
         cursor: pointer;
         .h-flex-col;
         .align-items(center);

         &.is-document-item {
            .icon(passport-full);

            &.is-completed:before {
               color: var(--color-ok);
            }
         }

         &.is-allergy-item {
            .icon(health-filled);
         }

         &.is-comment-item {
            .icon(comments-filled);
         }

         &:before { // icons
            color: var(--color-not-ok);
            font-size: calc((23 / var(--base-value)) * 1rem);
            margin-bottom: 3px;
         }
      }

      &__clear-btn {
         position: absolute;
         top: 20px;
         right: 50px;
         color: var(--color-red);
         opacity: 0;
         cursor: pointer;
         .flex-shrink(0);
         .icon(back-arrow);
         .transition;

         &:before {
            margin-right: 4px;
         }
      }

      &__uncollapse-btn {
         position: absolute;
         top: 20px;
         right: 15px;
         color: var(--color-gray-4);
         font-size: calc((18 / var(--base-value)) * 1rem);
         cursor: pointer;
         .icon(arrow-down);
         .transition;

         &:hover {
            color: var(--color-gray) !important;
         }
      }

      &__collapsed-row-title {
         text-transform: uppercase;
         margin: 50px 0 8px 0;
         color: var(--color-gray-2);
         .h-font-bold;
      }

      &__input-label {
         display: block;
         color: var(--color-gray-2);
         padding-bottom: 2px;
         .h-font-light;

         &.is-required {
            .h-required-input-label;
         }
      }

      // when row is completed
      &.is-completed {
         .b-participant-table-row__student-count:before { // hide red dot
            display: none;
         }
      }

      // on row hover
      &:hover {
         .b-participant-table-row__clear-btn {
            opacity: 1;
         }

         .b-participant-table-row__uncollapse-btn {
            color: var(--color-gray-3);
         }
      }

      // when row uncollapsed
      &.is-row-uncollapsed {
         .b-participant-table-row__uncollapse-btn { // rotate uncollapse arrow
            .transform(rotate(180deg));
         }
      }

      @media only screen and (max-width : @breakpoint-lg) {
         &__top-wrap {
            .flex-wrap(wrap);
         }

         &__infos-wrap {
            width: 100%;
            margin-top: 15px;
         }

         &__info-item {
            margin-left: 30px;
            .h-flex-row-left-center;

            &:before { // icons
               margin-bottom: 0;
               margin-right: 5px;
            }
         }
      }

      // collapsible transition
      .toggle-row-enter-active {
         transition: height 0.3s ease-in 0s, opacity 0.3s 0.3s;
      }

      .toggle-row-leave-active {
         transition: height 0.3s ease-out 0s;
      }
   }
</style>