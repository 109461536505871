<template>
   <div class="b-participant-table">
      <div class="b-participant-table__column-labels">
         <div class="b-participant-table__label-first-name is-required">
            {{ $t("first_name") }} 
         </div>

         <div class="b-participant-table__label-last-name is-required">
            {{ $t("last_name") }}
         </div>

         <div class="b-participant-table__label-gender is-required">
            {{ $t("gender") }}
         </div>

         <div class="b-participant-table__label-birthday is-required">
            {{ $t("birth") }}
         </div>
      </div>

      <!-- rows for registered participants -->
      <ParticipantRow
         v-for="(participant, index) in registeredParticipants"
         :participantProp="participant"
         :participantNumber="index + 1"
         :key="participant.id"
      />

      <!-- rows for missing participants -->
      <ParticipantRow
         v-for="(participant, index) in missingParticipants"
         :participantProp="participant"
         :participantNumber="index + registeredParticipants.length + 1"
         :key="index + registeredParticipants.length"
      />
   </div>
</template>

<script>
   import { mapState, mapActions } from 'vuex';
   import participantType from '@/constants/participantType.js';
   import ParticipantRow from '@/components/participant_list/ParticipantRow.vue';

   export default {
      components: {
         ParticipantRow,
      },
      props:{
         participantType: {
            type: String,
            default: participantType.STUDENT,
         },
      },
      data() {
         return {
            missingParticipants: [],
         }
      },
      computed: {
         ...mapState({
            groupStructure: state => state.groupStructure,
            hasFligthTransportation: state => state.hasFligthTransportation,
            isClassTrip: state => state.isClassTrip,
            participants: state => state.participantList.participants,
            submit: state => state.participantList.submitParticipants,
         }),
         totalPax() {
            switch(this.participantType) {
               case participantType.TRAVELLER:
                  return this.groupStructure.total;
               case participantType.STUDENT:
                  return this.groupStructure.kidsTotal;
               case participantType.TEACHER:
                  return this.groupStructure.adultsTotal;
               default:
                  return this.groupStructure.total;
            }
         },
         registeredParticipants() {
            switch(this.participantType) {
               case participantType.TRAVELLER:
                  return this.participants;
               case participantType.STUDENT:
                  return this.participants.filter(p => p.is_student);
               case participantType.TEACHER:
                  return this.participants.filter(p => !p.is_student);
               default:
                  return this.participants;
            }
         },
         isStudentTable() {
            return this.participantType == participantType.STUDENT;
         },
         showPassportInput() {
            return this.hasFligthTransportation;
         },
         emptyParticipant() {
            return {
               first_name: '',
               last_name: '',
               gender: null,
               nationality: 49,
               birth_date: '',
               allergies: '',
               others: '',
               room: '',
               document_nr: null,
               is_vaccinated: false,
               is_healthy: false,
               document_type: null,
               document_issue_date: '',
               document_expiration_date: '',
               document_issuing_country_id: 49,
               is_student: this.isStudentTable
            };
         },
         numberOfMissingParticipants() {
            return this.totalPax - this.registeredParticipants.length;
         },
         emptyParticipantsArray() {
            let missingParticipantsArray = [];
            for(var i = 0; i < this.numberOfMissingParticipants; i ++) {
               missingParticipantsArray.push({...this.emptyParticipant});
            }
            return missingParticipantsArray;
         },
         participantsToSubmit() {
            let participants = [...this.registeredParticipants, ...this.missingParticipants];

            return participants.filter(this.participantsHasFirstNameFilled);
         },
      },
      methods: {
         ...mapActions([
            'submitParticipants',
         ]),
         participantIsFilled(participant) {
            return  !!participant.first_name
               && !!participant.last_name
               && !!participant.gender
               && !!participant.nationality
               && !!participant.birth_date
               && !!participant.document_nr;
         },
         participantsHasFirstNameFilled(participant) {
            return !!participant.first_name;
         },
      },
      watch: {
         submit(val) {
            if (!val) {
               return;
            }

            this.submitParticipants(this.participantsToSubmit);
         }
      },
      mounted() {
         this.missingParticipants = [...this.emptyParticipantsArray];
      },
   };
</script>

<style lang="less">
   .b-participant-table {
      // row columns widths & gap
      --name-width: 180px;
      --last-name-width: 180px;
      --gender-width: 140px;
      --nationality-width: 180px;
      --allergies-width: 250px;
      --comments-width: 250px;
      --document-type-width: 150px;
      --document-number-width: 150px;
      --document-country-width: 200px;

      --gap: 25px;
      --labels-left-margin: 45px;

      overflow-x: auto;

      &__column-labels {
         color: var(--color-gray-2);
         position: relative;
         height: 16px;
         .h-flex-row-left-center;
         .h-font-light;

         .is-required {
            .h-required-input-label;
         }
      }

      &__label-first-name {
         position: absolute;
         left: 45px;
      }

      &__label-last-name {
         position: absolute;
         left: calc(~"var(--labels-left-margin) + var(--gap) + var(--name-width)");
      }

      &__label-gender {
         position: absolute;
         left: calc(~"var(--labels-left-margin) + var(--gap) + var(--name-width) + var(--gap) + var(--last-name-width)");
      }

      &__label-birthday {
         position: absolute;
         left: calc(~"var(--labels-left-margin) + var(--gap) + var(--name-width) + var(--gap) + var(--last-name-width) + var(--gap) + var(--gender-width)");
      }
   }
</style>