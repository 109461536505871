<template>
   <template v-if="accessGranted">
      <!-- title -->
      <div class="h-title"> {{ $t("participants_list") }} </div>

      <ParticipantsSummary />

      <template v-if="accessGranted">
         <!-- tabs nav -->
         <div class="h-tab-nav h-page-block-separation">
            <!-- students tab (only for schooltrip-->
            <div
               v-if="isClassTrip"
               @click="tabClick"
               class="h-tab-nav__item"
               :class="{ 'is-active' : selectedTab == 1 }"
            >
               <div class="h-tab-nav__item-main">
                  {{ groupStructure.kidsTotal }} {{ $t("students") }}
               </div>

               <!-- missing students -->
               <div class="p-participant-list__tabs-missing-count" v-if="missingKidsCount > 0">
                  <div class="h-badge" >
                     {{ missingKidsCount }}
                  </div>

                  {{ $t('missing') }}
               </div>

               <!-- too many students -->
               <div class="p-participant-list__tabs-message is-warning"  v-if="warnings.tooManyStudentsStored">
                  {{ $t("too_many_students_stored") }}
               </div>

               <!-- students completed -->
               <div class="p-participant-list__tabs-message" v-if="missingKidsCount == 0">
               </div>
            </div>

            <!-- adults tab (teachers or treavellors, depending on trip type-->
            <div
               class="h-tab-nav__item"
               @click="tabClick"
               :class="{ 'is-active': !isClassTrip || selectedTab == 2 }"
            >
               <div class="h-tab-nav__item-main">
                  <template v-if="isClassTrip">
                     {{ groupStructure.adultsTotal }} {{ $t("teachers") }}
                  </template>

                  <template v-else >
                     {{ groupStructure.total }} {{ $t("travellers") }}
                  </template>
               </div>

               <!-- missing adults -->
               <div class="p-participant-list__tabs-missing-count" v-if="missingAdultsCount > 0">
                  <div class="h-badge" >
                     {{ missingAdultsCount }}
                  </div>

                  {{ $t('missing') }}
               </div>

               <!-- rest of messages (warnings & complete) -->
               <template v-for="(warning, index) in participantWarnings" :key="index">
                  <div 
                     class="p-participant-list__tabs-message"
                     :class="warning.class"
                  >
                     {{ warning.message }}
                  </div>
               </template>
            </div>
         </div>

         <!-- tabs content -->
         <div class="h-white-block has-no-top-border">
            <template v-if="isClassTrip">
               <ParticipantsTable :key="participants" participantType="student" v-show="selectedTab == 1" />
               <ParticipantsTable :key="participants" participantType="teacher" v-show="selectedTab == 2"/>
            </template>

            <ParticipantsTable :key="participants" participantType="traveller" v-if="!isClassTrip"/>
         </div>
      </template>

      <template v-if="!isPartner">
         <div class="p-participant-list__required-fields-clarification">
            * <span v-html="$t('required_field_clarification')"></span>
         </div>

         <div class="p-participant-list__update-pax-hint">
            Nach dem Speichern brauchen Sie nichts zu tun, die abgespeicherte Liste wird uns automatisch mitgeteilt.
            <br>
            Um die Anzahl der Teilnehmenden zu ändern, senden Sie bitte eine Email an db-klassenfahrten@deutschebahn.com
         </div>
      </template>
   </template>
</template>

<script>
   import stopLoadingOnMounted from '@/mixins/stopLoadingOnMounted.js';
   import ParticipantsTable from '@/components/participant_list/ParticipantsTable.vue';
   import ParticipantsSummary from '@/components/participant_list/ParticipantsSummary.vue';
   import { mapState, mapGetters } from 'vuex';

   export default {
      components: {
         ParticipantsTable,
         ParticipantsSummary,
      },
      mixins: [
         stopLoadingOnMounted,
      ],
      data() {
         return {
            selectedTab: 1,
         }
      },
      computed: {
         ...mapState({
            isClassTrip: state => state.isClassTrip,
            isPartner: state => state.isPartner,
            accessGranted: state => state.accessGranted,
            groupStructure: state => state.groupStructure,
            warnings: state => state.warnings,
            participants: state => state.participantList.participants,
         }),
         ...mapGetters([
            'missingKidsCount',
            'missingAdultsCount'
         ]),
         studentsTabClass() {
            return this.missingKidsCount > 0 || this.warnings.tooManyStudentsStored
               ? 'is-warning'
               : 'is-success';
         },
         teachersTabClass() {
            return this.missingAdultsCount > 0 || this.warnings.tooManyTeachersStored
               ? 'is-warning'
               : 'is-success';
         },
         participantWarnings() {
            let warningMessages = [];

            if (this.warnings.tooManyTeachersStored && this.isClassTrip) {
               warningMessages.push({
                  message: this.$t("too_many_teachers_stored"),
                  class: 'is-warning'
               });
            }

            if ((this.warnings.tooManyTeachersStored || this.warnings.tooManyStudentsStored) && !this.isClassTrip) {
               warningMessages.push({
                  message: this.$t("too_many_travellers_stored"),
                  class: 'is-warning'
               });
            }

            if (this.missingAdultsCount == 0 && this.isClassTrip) {
               warningMessages.push({
                  message: '',
               });
            }

            if (this.missingAdultsCount + this.missingKidsCount == 0 && !this.isClassTrip) {
               warningMessages.push({
                  message: '',
               });
            }

            return warningMessages;
         }
      },
      methods: {
         tabClick() {
            if (this.selectedTab == 1) {
               this.selectedTab = 2;
            } else {
               this.selectedTab = 1;
            }
         }
      }
   };
</script>

<style lang="less">
   .p-participant-list {
      .h-tab-nav {
         z-index: 1; // make tabs appear over shadow of participants list
         position: relative;

         &__item-main {
            margin-right: 10px;
         }
      }

      &__tabs-message {
         color: var(--color-ok);
         margin-left: 15px;
         .icon(tick);
         .h-flex-row-left-center;

         &:before { // icon
            margin-right: 5px;
            font-size: calc((20 / var(--base-value)) * 1rem);
         }

         &.is-warning {
            color: var(--color-not-ok);
            .icon(warning-filled);
         }
      }

      &__tabs-missing-count {
         color: var(--color-not-ok);
         .h-flex-row-left-center;

         .h-badge {
            margin-right: 5px;
         }
      }

      &__completed-icon {
         color: var(--color-ok);
         font-size: calc((20 / var(--base-value)) * 1rem);
         .icon(tick);
      }

      &__required-fields-clarification {
         margin-top: 20px;
         color: var(--color-gray-2);
         .h-font-light;
      }

      &__update-pax-hint {
         text-align: center;
         font-size: calc((16 / var(--base-value)) * 1rem);
         margin-top: var(--page-block-separation);

         strong,
         b {
            .h-font-bold;
         }
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         .h-tab-nav {
            .h-flex-col;

            &__item {
               width: 100%;
            }
         }
      }
   }
</style>
